import { IS_CUSTOM_LOGIN, USER_CLIENT_TOKEN_INFO } from '@algomusinc/domain';
import { OktaAuthService } from '@algomusinc/okta';
import { LocalstorageService } from '@algomusinc/services';
import { Component, Injector, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/shell/src/environments/environment';

@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styleUrls: ['./unauthorized-access.component.scss'],
})
export class UnauthorizedAccessComponent implements OnInit {
  issuer: any;
  idToken: any;
  redirectUri: any;
  private oktaAuth!: OktaAuthService;
  constructor(private injector: Injector, private storage: LocalstorageService, private router: Router) {
    if(environment.okta.enableOkta) {
      this.oktaAuth = <OktaAuthService>this.injector.get(OktaAuthService);
    }
  }

  ngOnInit() {}
  async logOut() {
    if (this.storage.get(IS_CUSTOM_LOGIN)) {
      localStorage.clear();
      this.router.navigate(['/_admin']);
    } else if (environment.okta.enableOkta) {
      const OKTA_DOMAIN = environment.okta.OKTA_DOMAIN;
      // `dev-30785815.okta.com`;
      const AUTHORIZATION_SERVER = environment.okta.AUTHORIZATION_SERVER; //`/oauth2/default`;

      const ISSUER = environment.okta.addAuthorizationServer
        ? `https://${OKTA_DOMAIN}/${AUTHORIZATION_SERVER}`
        : `https://${OKTA_DOMAIN}`;

      this.redirectUri = environment.okta.redirectUri; // 'https://localhost:4200/login/callback';
      this.idToken = this.oktaAuth.oktaAuth.getIdToken();
      this.issuer = ISSUER;
      this.oktaAuth.oktaAuth.tokenManager.clear();
      this.oktaAuth.oktaAuth.signOut();
      this.storage.remove(USER_CLIENT_TOKEN_INFO);

      window.location.href = `${this.issuer}/v1/logout?id_token_hint=${this.idToken}&post_logout_redirect_url=${this.redirectUri}`;
    } else {
      localStorage.clear();
      window.location.href = window.origin;
    }

  }
}
