
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the ${env} environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json` hello.

const notificationHub = `https://notificationapi-algoplus-demo-qa.azurewebsites.net`;
const targetBaseUrl = 'https://localhost:44320/api';
const env = 'https://algocoreprod-acc-api.algo.com/api';
const configuration = 'https://algocoreprod-config-api.algo.com/api';
const FORECAST_API = "https://forecastapi-algocore-fc-engine-shared-dev.azurewebsites.net/api";
const amazonScraper = `https://localhost:7273/api`;
const bot = `https://directline.botframework.com/v3/directline`;
const CLUSTER_API = "https://clustering-algocore-fc-engine-shared-dev.azurewebsites.net";
const BBY_API = "https://as-web-bbyapi-play-dev.azurewebsites.net/api";
const MSG_BASEURL = "https://mucapi-algocore-fc-engine-shared-dev.azurewebsites.net";
const PMS_API = "https://as-web-pms-api-play-dev.azurewebsites.net/api";
const QUICKPITCH_API = "https://eus-dev-play-as-quickpitch-api.azurewebsites.net/api";
const ALGOCORE_API_URL_DATABRICKS = "https://algocoreprod-core-api.algo.com";
const ALGOCORE_API_URL_SNOWFLAKE = "";

export const environment = {
  showHomeBase: false,
  algoCoreSearchBot:true,
  algoCoreMultiAsk: false,
  enableSignalR: false,
  isTeamBasedMenus: false,
  isJWTaApiToken: false,
  enableJourney: false,
  isBBYBaseClient: false,
  isDefaultManualLogin: true,
  showSaveSelections: true,
  notificationAlerts: false,
  NlPAutoComplete: false,
  Notifications: true,
  HomePage_Favorite: false,
  HomePage_UpcomingTask: false,
  HomePage_KPI: false,
  HomePage_Graph: false,
  HomePage_Enable: false,
  Journey_Enable: false,
  Journey_CreateJourney: false,
  Journey_MergeJourney: false,
  Journey_PlannersJourney: false,
  Journey_InlineAnalytics: false,
  Journey_Notifications: false,
  Journey_CreateAlert: true,
  Journey_EnableContentCreation: false,
  Journey_Notes: false,
  KPI_Enable: true,
  KPI_RecommendJourneys: false,
  KPI_HomepageKPIs: false,
  KPI_KpiGraphToggle: false,
  KPI_HideOutcomeColumn: true,
  KPI_Notifications: true,
  okta: {
    enableOkta: false,
    addAuthorizationServer: true,
    OKTA_DOMAIN: `dev-30785815.okta.com`,
    CLIENT_ID: `0oa3zpnz4ejr7Etoi5d7`,
    CALLBACK_PATH: '/login/callback',
    AUTHORIZATION_SERVER: `/oauth2/default`,
    PROTOCOL: `https`,
    redirectUri: 'https://eus-dev-play-as-portal.azurewebsites.net/login/callback',
    changePassword: 'https://dev-30785815.okta.com/enduser/settings',
    logoutUri: 'https://eus-dev-play-as-portal.azurewebsites.net',
    homeUri: 'https://eus-dev-play-as-portal.azurewebsites.net',
    showAlgoLogoutPage: false,
    showChangePassword: false,
    isEnable: false
  },
  agGridLicenseKey: `CompanyName=Algomus Inc,LicensedGroup=Algo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-037124,SupportServicesEnd=18_March_2024_[v2]_MTcxMDcyMDAwMDAwMA==31baa4eb45af976d60368922cbd068c8`,
  headerLogoURL: '',
  headerLogoEarth: '',
  headerLogoEarthHeight: 0,
  logoWidthEarth: 0,
  digitaWallet_buttonName: 'open',
  loginLogoURL: '',
  logoWidth: 70,
  logoWidthLogin: 200,
  logoWidthForget: 200,
  microsoftURL: `https://login.microsoftonline.com/dd70a646-992f-4f0e-a15e-d9c554e1f576/oauth2/v2.0/token`,
  ClientId: 12,
  production: false,
  idleTime: 8,
  fusionChartsKey: ' qqA3ydjA5C5B3A5F5G4B3E3A10B5E2B2E2ixrG1C3gcowg1B3I4F-7mC-16B5B2D3D1G1F4H4B1C8A4D2G5G3C1G2B1ippB1A4B4i1A33A18B14utcB4A2H4moA-31A7C8A3D-11geH2C2C5A9B3B5F3G4F1C2F1zllC7E2f1G4i1dB-22C6D6B5jyD3RF1B5wwf1C2A3E2E3G3C9A6E4E4C4G3B2H2H4B-21==',
  AccessToken: `${configuration}/PowerBI/GetAccessToken`,
  BotSecretKey: `Y8noDdyqI2w.aFhA-ZQYDoFjdVZr-uX59wVyobs6ls1J3ukfeH_1-8w`,
  mapApi: `AIzaSyCH4mv9Pw3dRWz0yUoMyf4IexqPNUBdzvw`,
  utcOffset: 180,
  AppTokenGenerate: `${bot}/tokens/generate`,
  AppTokenRefresh: `${bot}/tokens/refresh`,
  AppConversations: `${bot}/conversations`,
  AppAccount: `${env}/Account`,
  AppClaim: `${env}/Claim`,
  AppClaimGroup: `${env}/ClaimGroup`,
  AppRole: `${env}/Role`,
  AppCalender: `${configuration}/Calender`,
  AppSubscription: `${configuration}/Subscription`,
  AppUserPreference: `${configuration}/UserPreference`,
  AppTaskRequest: `${configuration}/TaskRequest`,
  AppTask: `${configuration}/Task`,
  AppJourney: `${configuration}/Journey`,
  AppJourneyLookUp: `${configuration}/JounreyLookUp`,
  AppJourneyCollaboration: `${configuration}/JourneyCollaboration`,
  AppJourneyContextLog: `${configuration}/JourneyContextLog`,
  AppJourneyContextLogComment: `${configuration}/JourneyContextLogComment`,
  AppJourneyInsightComments: `${configuration}/JourneyInsightComments`,
  AppJourneyUserActivityLog: `${configuration}/JourneyUserActivityLog`,
  AppPowerBi: `${configuration}/PowerBi`,
  AppCategory: `${configuration}/Category`,
  AppChannel: `${configuration}/Channel`,
  AppClient: `${configuration}/Client`,
  AppDistributor: `${configuration}/Distributor`,
  AppForms: `${configuration}/Forms`,
  AppSource: `${configuration}/Sourcing`,
  AppGoal: `${configuration}/Goal`,
  AppGoalType: `${configuration}/GoalTypes`,
  AppGoalTenure: `${configuration}/GoalTenureTypes`,
  AppSourceMixForm: `${configuration}/SourceMixForm`,
  AppSourceMixRow: `${configuration}/SourceMixRow`,
  AppSourceMixHeader: `${configuration}/SourceMixHeader`,
  AppCategoryMixRow: `${configuration}/CategoryMixRow`,
  AppCategoryMixHeader: `${configuration}/CategoryMixHeader`,
  AppCategoryMixForm: `${configuration}/CategoryMixForm`,
  AppStoreMixRow: `${configuration}/NewFormRow`,
  AppStoreMixHeader: `${configuration}/NewFormHeader`,
  AppStoreMixForm: `${configuration}/NewForm`,
  AppSameStoreRow: `${configuration}/SameStoreRow`,
  AppSameStoreHeader: `${configuration}/SameStoreHeader`,
  AppSameStoreForm: `${configuration}/SameStoreForm`,
  AppCalenderActivities: `${configuration}/CalendarActivity`,
  AppEventTypes: `${configuration}/EventTypes`,
  AppActionItems: `${configuration}/ActionItems`,
  AppChannelSourceWise: `${configuration}/ChannelSourceWise`,
  AppMenu: `${configuration}/Menu`,
  AppAnnouncement: `${configuration}/Announcement`,
  AppNote: `${configuration}/Note`,
  AppAPBudgetType: ``,
  AppPromotion: ``,
  AppSKU: ``,
  AppSKUPromotion: `Promotion`,
  AppDecayCurve: ``,
  AppItemMaster: ``,
  AppBussinessAttribute: ``,
  AppBussinessInformation: ``,
  AppWorkgroup: ``,
  AppLevel: ``,
  AppUserLevel: ``,
  UploadDoc: ``,
  //UploadDoc: `https://localhost:44399/api/AnomalyDetector/Upload`,
  Anomoly: ``,
  AnomolyFiles: ``,
  SyncPowerBIReports: `${configuration}/PowerBI/GetReportList`,
  NotificationCommon: `${configuration}/notification`,
  NotificationHub: `${notificationHub}/notifications`,
  ApplicationInsights: `${configuration}/ApplicationInsights`,
  AppDocument: ``,
  AppCommonCode: `${configuration}/CommonCode`,
  AppSuggestAdmin: ``,
  AppCurrency: `${configuration}/CurrencySetting`,
  AppTeams: `${env}/Team`,
  AppFBSpreading: `${configuration}/Spreading`,
  AppCurrencyRate: `${configuration}/CurrencyRate`,
  AppBuyerSetting: `${configuration}/BuyerSetting`,
  AppFinanceSetting: `${configuration}/FinancialSetting`,
  AppProductSetting: `${configuration}/ProductSetting`,
  AppFBForcast: `${configuration}/FBForcast`,
  AppItemManager: `${configuration}/ItemManager`,
  AppTeamScreenGroup: `${configuration}/TeamScreenGroup`,
  AppItemForcast: `${configuration}/ItemForecast`,
  AppAccountSettingMapping: `${configuration}/AccountSettingMapping`,
  AppProductSettingMapping: `${configuration}/ProductSettingMapping`,
  FbForecastProfile: `${configuration}/FBMeasureProfile`,
  MsForecastProfile: `${configuration}/MSMeasureProfile`,
  PromotionPlanner: `${configuration}/PromotionPlanner`,
  OrderList: `${configuration}/MSOrderList`,
  AppBulkCommit: `${configuration}/BulkCommitJob`,
  maxPlannerSaveCount: 20,
  Ess: '',
  ClusterAPI: `${CLUSTER_API}/cluster-api`,
  ClusterBaseUrl: `${CLUSTER_API}`,
  FileManagementURL: `${CLUSTER_API}/file-management-api`,
  chuckUploadConfig: {
    noOfChunks: 20,
    minFileChuckSize: 1024 * 1024 * 10, //10MB
    blockSize: {
      size512KB: 1024 * 512,
      size4Mb: 1024 * 1024 * 4,
      size32Mb: 1024 * 1024 * 32,
      size10MB: 1024 * 1024 * 10
    }
  },
  ScreenPreference: `${env}/ScreenPreference`,
  outlierManager: `${FORECAST_API}/OutlierManager`,
  MappingManager: `${FORECAST_API}/MappingManager`,
  PromoManager: `${FORECAST_API}/PromoManager`,
  SeasonalityManager: `${FORECAST_API}/SeasonalityManager`,
  ForecastManager: `${FORECAST_API}/ForecastManager`,
  ForecastMapping: `${FORECAST_API}/Lookup`,
  HierarchyManager: `${FORECAST_API}/HierarchyManager`,
  ItemHierarchyManager: `${FORECAST_API}/ItemHierarchyManager`,
  LocationHierarchyManager: `${FORECAST_API}/LocationHierarchyManager`,
  ItemManagerForecast: `${FORECAST_API}/ItemManagerForecast`,
  LocationManagerForecast: `${FORECAST_API}/LocationManagerForecast`,
  CombinationManager: `${FORECAST_API}/CombinationManager`,
  autoCompleteApi: 'https://autocomplete-api-demo.azurewebsites.net',
  GenAiapi: `https://questionanswer-gen-ai-api-demo.azurewebsites.net`,
  SkylightForecast: `${targetBaseUrl}/Forecast`,
  TargetBaseUrl: `${targetBaseUrl}`,
  KPIList: `${configuration}/KPI`,
  CoreArea: `${configuration}/CoreArea`,
  KPISubscription: `${configuration}/KPISubscription`,
  KPISlicer: `${configuration}/KPISlicer`,
  ApplicationInsightsQP: `${QUICKPITCH_API}/ApplicationInsights`,
  Empower: `${QUICKPITCH_API}/QuickPitch`,
  EmpowerReport: `${QUICKPITCH_API}/Report`,
  appInsights: {
    instrumentationKey: "99265e8c-584d-46f0-b98d-93106f887eb8"
  },
  PMS: `${PMS_API}`,
  PlanogramUrl: `${CLUSTER_API}/api/fosnr`,
  GlobalEntity: `${configuration}/Global`,
  Homebase: `${configuration}/HomeBase`,
  AppMasterItem: `${BBY_API}/MasterItem`,
  AppChildItem: `${BBY_API}/ChildItem`,
  AppProductRating: `${BBY_API}/Rating`,
  AppProductClass: `${BBY_API}/Class`,
  AppProductSubClass: `${BBY_API}/SubClass`,
  AppProductAccount: `${BBY_API}/Account`,
  AppItemPromotion: `${BBY_API}/PromotionItem`,
  AppItemPromotionPlanner: `${BBY_API}/PromotionItemPlanner`,
  AppProductLookup: `${BBY_API}/Lookup`,
  AppFixtureMapping: `${BBY_API}/Fixture`,
  KPITile: `${configuration}/KPITile`,
  WebPush: `${configuration}/WebPush`,
  VapidPublicKey: "BPfqBgB2oNNQn0Wywm9df6QiLz5fJold15DynhsYPZJIB2LHoPmp7X9G4LQzMUOhLal8z5ZJ1C5rUzwl0_p9Pyo",
  WebPushNotification: true,
  messagingHubUrl: `${MSG_BASEURL}/notify`,
  messagingUrl: `${MSG_BASEURL}/api/ApplicationUser`,
  createChannelUrl: `${MSG_BASEURL}/api/Channel`,
  userInChannel: `${MSG_BASEURL}/api/UsersInChannel`,
  channelMessage: `${MSG_BASEURL}/api/ChannelMessage`,
  messagingChatHubUrl: `${MSG_BASEURL}/chathub`,
  messagingChannelHubUrl: `${MSG_BASEURL}/channelhub`,
  POG: `${configuration}/POG`,
  MasterPromo: `${configuration}/MasterPromo`,
  LinkedItemMaster: `${targetBaseUrl}/LinkedItemMaster`,
  KPITrack: `${configuration}/KPITrack`,
  KPIError: `${configuration}/KPIErrorInfo`,
  CronSchedule: `${configuration}/CronSchedule`,
  configurationUrl: `${configuration}`,
  AlgocoreApiUrlDatabricks: `${ALGOCORE_API_URL_DATABRICKS}`,
  AlgocoreApiUrlSnowFlake: `${ALGOCORE_API_URL_SNOWFLAKE}`,
};
